import type React from "react";

export type EventType = keyof WindowEventMap;
export type SyntheticEvent<T extends EventType> = React.SyntheticEvent<
  Element,
  WindowEventMap[T]
> & {
  target: Element & EventTarget;
};

export const createEvent = <T extends EventType>(
  type: T,
  target: {
    name: string | undefined;
    value?: any;
    checked?: boolean;
  },
) => {
  const event = new Event(type, {
    bubbles: true,
  });
  Object.defineProperty(event, "target", {
    value: target,
  });
  return event as unknown as SyntheticEvent<T>;
};
