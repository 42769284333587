export const ROW_HEIGHT = 16;
export const GAP = 10;

export const EDITOR_MENU_WIDTH = 390;
export const EDITOR_MOBILE_MENU_HEIGHT = 56;

export const BREAKPOINTS = {
  desktop: { min: 920, max: 9999 },
  mobile: { min: 0, max: 920 },
} as const;
export const COLS = { mobile: 8, desktop: 16 } as const;

export const EDITOR_MOBILE_BREAKPOINT = BREAKPOINTS.desktop.min + EDITOR_MENU_WIDTH;
